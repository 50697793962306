.create-container {
    padding: 36px;
    .create-section {
        margin: 13px 0;
        padding: 12px 27px 0 27px;
        background-color: white;
    }
    
    .section-title {
        width: 100%;
        padding: 8px;
        font-weight: 600;
        font-size: 16px;
    }

    .ant-tree {
        width: 100%;
    }

    .title {
        font-weight: 700;
        font-size: 24px;
    }

    .btn {
        border-radius: 6px;
        padding: 5px 12px;
    }

    .ant-table {
        font-size: 14px !important;
        font-weight: 500;

        .expand-icon {
            svg {
                fill: #1890ff;
            }
        }

        .ant-table-thead>tr>th {
            font-size: 14px;
            background: #dbeafe;
        }

        th.ant-table-cell::before {
            display: none;
        }

        .ant-table-row-level-0:nth-child(2n+2) {
            background-color: white;
        }
        
        .ant-table-tbody > tr > td {
            align-items: center;

            .ant-form-item {
                margin: 0;
            }
        }

        .ant-table-row-level-1>td>a{
            margin: 0 -20px 0 20px;
        }
    }
}


.custom-drawer {
    .ant-form .ant-form-item {
        margin: 0 0 18px 0;
    }
    
    .drawer-title {
        span {
            font-weight: 500;
            font-size: 18px;
        }
    }
    
    .drawer-section {
        width: 100%;
        padding: 0 8px 8px 8px;
        font-weight: 500;
        font-size: 16px;
    }
}