.header-logo {
    background-image: url("../../../../assets/img/Logo-1.svg");
    background-size: cover;
    width: 100%;
    height: 100%;
    height: 60px;
    padding: 10px;
    width: 203px;
    margin-right: 16px;
}

/* header */
.header.ant-layout-header {
    /* min-height: 80px; */
    min-height: 30px;
    height: 30px;
    line-height: normal;
    background: white;
    padding: 0px;
    border-bottom: 1px solid #eaedf1;
}

.header.ant-layout-header>div {
    /* padding: 9px 27px; */
    padding: 0px 27px;
}

.header.ant-layout-header>div:first-child {
    width: 300px;
}

.header {
    display: flex;
    justify-content: space-between;
}